import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import GenericPage from '@templates/Generic/Page'
import { Code } from '@components'

const PlaybookArticle: FC<PageProps<{ article: any }>> = ({
  data: {
    article: {
      frontmatter: { title },
      ...article
    }
  }
}) => (
  <GenericPage
    title={title}
    breadcrumbs={[
      {
        title: 'Forensic Playbook',
        to: '/forensic-playbook/'
      },
      {
        title
      }
    ]}
  >
    <Code value={JSON.stringify(article, null, 4)} />
  </GenericPage>
)

export const query = graphql`
  query articleBySlug($slug: String!) {
    article: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      headings {
        value
        id
      }
      frontmatter {
        title
        tags
        slug
        lastUpdated
      }
      timeToRead
      tableOfContents
      html
      excerpt
    }
  }
`

export default PlaybookArticle
